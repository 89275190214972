import React from 'react'
import Hero from './layout/Hero'

const Home = () => {
  return (
    <>
      <Hero />
      {/* <section className="tour-search-one__home-two">
        <div className="container">
          <form
            className="tour-search-one wow fadeIn"
            action="tour-sidebar.html"
            data-wow-duration="1500ms"
          >
            <div className="tour-search-one__inner">
              <div className="tour-search-one__inputs">
                <div className="tour-search-one__input-box">
                  <label>Where to</label>
                  <input
                    type="text"
                    placeholder="Enter keywords"
                    name="place"
                    id="place"
                  />
                </div>
                <div className="tour-search-one__input-box">
                  <label>When</label>
                  <input
                    type="text"
                    placeholder="September"
                    name="when"
                    id="when"
                  />
                </div>
                <div className="tour-search-one__input-box">
                  <label>Type</label>
                  <select className="selectpicker" id="type">
                    <option value="Adventure">Adventure</option>
                    <option value="Adventure">Adventure</option>
                    <option value="Wildlife">Wildlife</option>
                    <option value="Sightseeing">Sightseeing</option>
                  </select>
                </div>
              </div>
              <div className="tour-search-one__btn-wrap">
                <button type="submit" className="thm-btn tour-search-one__btn">
                  Find now
                </button>
              </div>
            </div>
          </form>
        </div>
      </section> */}
    </>
  )
}

export default Home
