import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { useLocation } from 'react-router-dom'

import { useAuth } from './../../auth/AuthContext'

import 'font-awesome/css/font-awesome.min.css'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper from '@material-ui/core/Popper'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { Paper, Grow } from '@material-ui/core'
import Authenticate from '../Authenticate'

const Header = () => {
  const location = useLocation()
  const { signOut, isAuthenticated, user } = useAuth()

  const [open, setOpen] = useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [modalAuth, setModalAuth] = useState<boolean>(false)

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef &&
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleToggleModal = (action?: boolean) => {
    setModalAuth(action ? action : !modalAuth)
  }

  const onLogout = () => {
    signOut()
  }

  return (
    <div
      className={location.pathname === '/' ? 'site-header__home-two-wrap' : ''}
    >
      <div className="topbar-one">
        <div className="container-fluid">
          <div className="topbar-one__left">
            <a href="mailto:contacto@viajesaeromar.com">
              contacto@viajesaeromar.com
            </a>
            <a href="tel:+573136724959">+57 3136724959</a>
            <span>Medellin, Colombia</span>
          </div>
          <div className="topbar-one__right">
            <div className="topbar-one__social">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://api.whatsapp.com/send?phone=573136724959&text=Hola%2C%20estoy%20interesad%40%20en%20los%20servicios%20turisticos%20que%20publicas%20en%20viajesaeromar.com"
              >
                <i className="fa fa-whatsapp"></i>
              </a>
              {/* <a href="/">
                <i className="fa fa-instagram"></i>
              </a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/viajesaeromarsgv"
              >
                <i className="fa fa-facebook-square"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <header
        className={
          location.pathname === '/'
            ? 'main-nav__header-one site-header__home-two'
            : 'main-nav__header-one'
        }
      >
        <nav className="header-navigation stricky">
          <div className="container">
            <div className="main-nav__logo-box">
              <a
                href="/"
                className="main-nav__logo"
                style={{ color: '#fff', fontSize: '23px' }}
              >
                {/* <img
                src="images/logo-aeromar.png"
                className="main-logo"
                alt="Logo"
              /> */}
                viajesaeromar.com
              </a>
              <a href="/" className="side-menu__toggler">
                <i className="fa fa-bars"></i>
              </a>
            </div>

            <div className="main-nav__main-navigation">
              <ul className=" main-nav__navigation-box">
                <li>
                  <a href="/">Home</a>
                </li>
                {/* <li>
                  <a href="/contact">Contact</a>
                </li> */}
              </ul>
            </div>

            <div className="main-nav__right">
              <select name="currency" id="currency" className="selectpicker">
                <option value="USD">USD</option>
                <option value="GBP">GBP</option>
                <option value="AUD">AUD</option>
              </select>
              <select name="languages" id="languages" className="selectpicker">
                <option value="ENG">ENG</option>
                <option value="BN">BN</option>
                <option value="RUS">RUS</option>
              </select>
              {/*  <div
                  style={{
                    color: '#fff',
                    cursor: 'pointer',
                    marginRight: 0,
                    marginLeft: '30px',
                    fontSize: '21px',
                  }}
                >
                  <i
                    className="tripo-icon-magnifying-glass"
                    onClick={() => setModalAuth(!modalAuth)}
                  ></i>
                </div> */}
              {!isAuthenticated ? (
                <div
                  style={{
                    color: '#fff',
                    cursor: 'pointer',
                    marginRight: 0,
                    marginLeft: '30px',
                    fontSize: '21px',
                  }}
                >
                  <i
                    className="tripo-icon-avatar"
                    onClick={() => setModalAuth(!modalAuth)}
                  ></i>
                </div>
              ) : (
                <>
                  {user && (
                    <Button
                      ref={anchorRef}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      size="small"
                      style={{
                        fontFamily: 'Barlow Condensed',
                        color: '#fff',
                        cursor: 'pointer',
                        marginRight: 0,
                        marginLeft: '30px',
                        fontSize: '16px',
                      }}
                    >
                      <Avatar
                        alt={user && user.attributes.name}
                        src={
                          user.attributes.picture ||
                          JSON.parse(user && user.attributes.picture).data.url
                        }
                        style={{ marginRight: '5px' }}
                      />
                      {user && user.attributes.name.split(' ')[0]}
                    </Button>
                  )}
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              {/* <MenuItem onClick={() => {handleToggle(); history.push('/profile')}}>Profile</MenuItem> */}
                              <MenuItem onClick={onLogout}>Logout</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              )}
            </div>
          </div>
        </nav>
      </header>

      <div className={!modalAuth ? 'search-popup' : 'search-popup active'}>
        <div className="search-popup__overlay custom-cursor__overlay">
          <i
            className="fa fa-times"
            style={{
              position: 'absolute',
              right: '85px',
              top: '85px',
              fontSize: '21px',
              cursor: 'pointer',
            }}
            onClick={() => setModalAuth(!modalAuth)}
          ></i>
        </div>
        <div className="search-popup__inner">
          {/* {modalAuth && ( */}

          <Authenticate closeModal={handleToggleModal} />

          {/* )} */}
        </div>
      </div>
    </div>
  )
}

export default Header
