import React, { useEffect, useState } from 'react'
import './../../assets/css/slider.scss'

const Hero = () => {
  const backgrounds = [
    'images/backgrounds/banner-2-santamarta.jpg',
    'images/backgrounds/banner-2-penol.jpg',
    'images/backgrounds/banner-2-cartagena.jpg',
  ]

  const [radioSelected, setRadioSelected] = useState([true, false, false])

  const nextSlide = () => {
    const currentIndex = radioSelected.findIndex((item) => item === true)
    setRadioSelected(radioSelected.map((value) => (value = false)))
    const nextIndex = (currentIndex + 1) % radioSelected.length
    let arr = radioSelected.map((value) => (value = false))
    arr[nextIndex] = true
    setRadioSelected(arr)
  }

  useEffect(() => {
    let intervalId = setInterval(() => {
      nextSlide()
    }, 25000)

    return () => clearInterval(intervalId)
  })

  return (
    <section className="banner-two">
      <div className="banner-two__bg">
        <div className="css-slider">
          <input
            type="radio"
            name="slider-navigation"
            id="first-slide"
            className={radioSelected[0] ? 'active' : ''}
          />
          <input
            type="radio"
            name="slider-navigation"
            id="second-slide"
            className={radioSelected[1] ? 'active' : ''}
          />
          <input
            type="radio"
            name="slider-navigation"
            id="third-slide"
            className={radioSelected[2] ? 'active' : ''}
          />
          <div className="css-slider-navigation">
            <label
              className="css-slider-navigation-item"
              htmlFor="first-slide"
              onClick={() => nextSlide()}
            ></label>
            <label
              className="css-slider-navigation-item"
              htmlFor="second-slide"
              onClick={() => nextSlide()}
            ></label>
            <label
              className="css-slider-navigation-item"
              htmlFor="third-slide"
              onClick={() => nextSlide()}
            ></label>
          </div>
          <div className="css-slider-wrapper">
            <div className="css-slider-inner">
              <div
                id="actual-first-slide"
                className="css-slider-item"
                style={{ backgroundImage: `url("${backgrounds[0]}")` }}
              >
                <div className="css-slider-item-inner">
                  <div className="container">
                    <p>explore</p>
                    <h2>Santa Marta</h2>
                  </div>
                </div>
              </div>
              <div
                id="actual-second-slide"
                className="css-slider-item"
                style={{ backgroundImage: `url("${backgrounds[1]}")` }}
              >
                <div className="css-slider-item-inner">
                  <div className="container">
                    <p>explore</p>
                    <h2>el peñol</h2>
                  </div>
                </div>
              </div>
              <div
                id="actual-third-slide"
                className="css-slider-item"
                style={{ backgroundImage: `url("${backgrounds[2]}")` }}
              >
                <div className="css-slider-item-inner">
                  <div className="container">
                    <p>explore</p>
                    <h2>cartagena</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="assets/images/shapes/banner-two__flotated-text.png"
        alt=""
        className="banner-two__floated-text"
      />
      <div className="container">
        <p>&nbsp;</p>
        <h2>&nbsp;</h2>
      </div>
    </section>
  )
}

export default Hero
