import React from 'react'
import './assets/css/bootstrap.min.css'
import './assets/css/bootstrap-select.min.css'
import './assets/css/tripo-icons.css'
import './assets/css/theme.css'
import './assets/css/responsive.css'

import ReactGA from 'react-ga'

import { Switch, Route } from 'react-router-dom'

// components
import Header from './components/layout/Header'
import Home from './components/Home'
import Contact from './components/Contact'

const App = () => {
  ReactGA.initialize('UA-17045518-1')
  document.title = 'Viajes Aeromar - Agencia de viajes'

  return (
    <>
      <div className="page-wrapper">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <div className="site-footer__bottom site-footer__bottom-home-3 site-footer__bottom-home-8">
          <div className="container text-center justify-content-center">
            <p>
              <a href="/">www.viajesaeromar.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default App
