import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types'
import validate from 'validate.js'
import { Link, Zoom, CircularProgress } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

interface FormValues {
  [key: string]: string | boolean | undefined
  email?: string
  password?: string
}

interface TouchValues {
  [key: string]: string | boolean | undefined
  email?: boolean
  password?: boolean
}

interface ErrorValues {
  [key: string]: string | boolean | undefined
  email?: string
  password?: string
}

interface FormState {
  isValid: boolean
  values: FormValues
  touched: TouchValues
  errors: ErrorValues
}

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
}

export interface IState {
  loading: boolean
  error: any
  success?: boolean
  toggleRegister: (register?: boolean) => void
  signInHandler: (email: string, password: string) => void
  resendCode: (
    type: 'password' | 'authentication',
    email?: string,
    redirect?: boolean
  ) => void
}

const SignIn = ({
  signInHandler,
  toggleRegister,
  resendCode,
  error,
  loading,
  success,
}: IState) => {
  const [formState, setFormState] = useState<FormState>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors: any = validate(formState.values, schema)
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const resetInputValues = () => {
    setFormState({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    })
  }

  const handleSignIn = async (event: any) => {
    event.preventDefault()
    if (!formState.isValid) {
      return
    }
    const email = formState.values.email
    const password = formState.values.password
    if (!email || !password) {
      return
    }
    signInHandler(email, password)
    resetInputValues()
  }

  const handleResend = (event: any) => {
    event.preventDefault()
    const { email } = formState.values

    resendCode('password', email, true)
  }

  return (
    <>
      <form className="register-popup__form">
        <div className="row">
          <div className="col-md-6">
            <div className="input-group">
              <button
                type="button"
                className="thm-btn"
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                }
              >
                <i className="fa fa-facebook"></i>&nbsp; Signin with facebook
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-group">
              <button
                type="button"
                className="thm-btn thm-btn-disabled"
                disabled
              >
                <i className="fa fa-google"></i>&nbsp; Signin with google
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="text"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                value={(formState.values as any).email || ''}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={(formState.values as any).password || ''}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <button
                type="button"
                onClick={handleSignIn}
                disabled={!formState.isValid || loading}
                className={
                  !formState.isValid ? 'thm-btn thm-btn-disabled' : 'thm-btn'
                }
                style={{ width: '100%' }}
              >
                {!loading ? 'Login' : <CircularProgress />}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            Don't have an account?{' '}
            <Link
              /* style={{ cursor: 'pointer' }} */
              onClick={() => toggleRegister(true)}
            >
              Sign up
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            Forgot your password? click here to{' '}
            <Link onClick={handleResend}>Get a Password Recovery Code</Link>
          </div>
        </div>
        <Zoom
          in={!!error && !loading}
          style={{ transitionDelay: error ? '100ms' : '0ms' }}
        >
          <Alert severity="error" variant={'filled'}>
            <AlertTitle>Error</AlertTitle>
            <strong>{error && error.message}</strong>
          </Alert>
        </Zoom>
      </form>
    </>
  )
}

export default SignIn
