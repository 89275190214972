/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4fde4751-9065-44d1-895a-20c35e89f54b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dnz3PzD0S",
    "aws_user_pools_web_client_id": "6qs5shoi2ta56d5g3cuof1hdqv",
    "oauth": {
        "domain": "comviajesaeromare50720eb-e50720eb-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
