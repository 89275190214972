import React, { useState, useEffect } from 'react'
import { codePattern, passwordPattern } from '../../utils/validations'
import validate from 'validate.js'
import { Zoom, Link, CircularProgress } from '@material-ui/core'

interface FormValues {
  [key: string]: string | boolean | undefined
  code?: string
  email?: string
  password?: string
  confirmPassword?: string
}

interface TouchValues {
  [key: string]: string | boolean | undefined
  code?: string
  email?: string
  password?: string
  confirmPassword?: string
}

interface ErrorValues {
  [key: string]: string | boolean | undefined
  code?: string
  email?: string
  password?: string
  confirmPassword?: string
}

interface FormState {
  isValid: boolean
  values: FormValues
  touched: TouchValues
  errors: ErrorValues
}
export type CompleteResetHandler = (
  code: string,
  password: string,
  email?: string
) => void

export interface IState {
  loading: boolean
  error: any
  email?: string
  success?: boolean
  completeHandler: CompleteResetHandler
  resendCode: (type: 'password' | 'authentication', email?: string) => void
}

const Reset = ({
  completeHandler,
  resendCode,
  error,
  loading,
  email,
  success,
}: IState) => {
  const schema = {
    code: {
      presence: { allowEmpty: false, message: 'is required' },
      format: {
        pattern: codePattern,
        message: 'should be 5 numbers, no spaces or special characters.',
      },
    },
    password: {
      format: {
        pattern: passwordPattern,
        message:
          'should at least be 8 characters long, have uppercase letter(s), lowercase letter(s), number(s), and sepecial character(s)',
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: 'is required' },
      equality: 'password',
    },
    email: {
      presence: !email ? { allowEmpty: false, message: 'is required' } : false,
      email: true,
      length: {
        maximum: 64,
      },
    },
  }

  const [formState, setFormState] = useState<FormState>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors: any = validate(formState.values, schema)
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleReset = async (event: any) => {
    event.preventDefault()
    const { code, email, password } = formState.values

    if (!formState.isValid) {
      return
    }
    if (!code) {
      return
    }
    if (!password) {
      return
    }
    completeHandler(code, password, email)
  }

  const handleResend = (event: any) => {
    event.preventDefault()
    const { email } = formState.values
    resendCode('password', email)
  }

  return (
    <Zoom
      in={true}
      timeout={{ appear: 800, enter: 500, exit: 3000 }}
      style={{ transitionDelay: '100ms' }}
    >
      <form className="register-popup__form" onSubmit={handleReset}>
        {!email && (
          <div className="row">
            <div className="col-md-12">
              <div className="input-group">
                <input
                  type="text"
                  name="email"
                  placeholder="Email address"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="password"
                name="confirmPassword"
                placeholder="confirmPassword"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="text"
                name="code"
                placeholder="Authorization code"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <button
                type="submit"
                disabled={!formState.isValid || loading}
                className={
                  !formState.isValid || loading
                    ? 'thm-btn thm-btn-disabled'
                    : 'thm-btn'
                }
                style={{ width: '100%' }}
              >
                {!loading ? 'Reset' : <CircularProgress />}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            Didn't get the code or it expired? Click here to{' '}
            <Link onClick={handleResend}>Send the Code again</Link>
          </div>
        </div>
      </form>
    </Zoom>
  )
}

export default Reset
