import React from 'react'

const Contact = () => {
  return (
    <>
      <section
        className="page-header"
        style={{
          backgroundImage: 'url(images/backgrounds/page-header-contact.jpg)',
        }}
      >
        <div className="container">
          <h2>Contact</h2>
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="index.html">Home</a>
            </li>
            <li>
              <span>Contact</span>
            </li>
          </ul>
        </div>
      </section>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="contact-info-one__single">
                <div className="contact-info-one__icon">
                  <i className="tripo-icon-placeholder"></i>
                </div>
                <div className="contact-info-one__content">
                  <p>
                    050030, Medellin, Antioquia <br />
                    Colombia
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-info-one__single">
                <div className="contact-info-one__icon">
                  <i className="tripo-icon-phone-call"></i>
                </div>
                <div className="contact-info-one__content">
                  <p>
                    {/* Local: <a href="tel:666-999-0000">+57 313 672</a> <br /> */}
                    Mobile: <a href="tel:+573136724959">+57 3136724959</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-info-one__single">
                <div className="contact-info-one__icon">
                  <i className="tripo-icon-message"></i>
                </div>
                <div className="contact-info-one__content">
                  <p>
                    <a href="mailto:contacto@viajesaeromar.com">
                      contacto@viajesaeromar.com
                    </a>{' '}
                    <br />
                    <a href="mailto:info@viajesaeromar.com">
                      info@viajesaeromar.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="contact-one__content">
                <div className="block-title text-left">
                  <p>Contact with us</p>
                  <h3>
                    Have any Question? <br />
                    feel free to contact <br />
                    with us.
                  </h3>
                </div>
                <div className="contact-one__content-text">
                  <p></p>
                </div>
                <div className="contact-one__social">
                  <a href="/">
                    <i className="fa fa-whatsapp"></i>
                  </a>
                  <a href="/">
                    <i className="fa fa-facebook-square"></i>
                  </a>
                  <a href="/">
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <form action="inc/sendemail.php" className="contact-one__form">
                <div className="row low-gutters">
                  <div className="col-md-6">
                    <div className="input-group">
                      <input type="text" name="name" placeholder="Your Name" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                      <input type="text" name="subject" placeholder="Subject" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group">
                      <textarea
                        name="message"
                        placeholder="Write Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-group">
                      <button
                        type="submit"
                        className="thm-btn contact-one__btn"
                      >
                        Send message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
