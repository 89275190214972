import React, { useState, useEffect } from 'react'
import { Link, Zoom, CircularProgress } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import validate from 'validate.js'
import { passwordPattern } from '../../utils/validations'

interface FormValues {
  [key: string]: string | boolean | undefined
  email?: string
  name?: string
  password?: string
}

interface TouchValues {
  [key: string]: string | boolean | undefined
  email?: boolean
  password?: boolean
}

interface ErrorValues {
  [key: string]: string | boolean | undefined
  email?: string
  password?: string
}

interface FormState {
  isValid: boolean
  values: FormValues
  touched: TouchValues
  errors: ErrorValues
}

export type CompleteRegistrationHandler = (
  name: string,
  email: string,
  password: string,
  picture: string
) => void

interface IState {
  loading: boolean
  error: any
  completeHandler: CompleteRegistrationHandler
  toggleRegister: (register?: boolean) => void
}

const Register = ({
  completeHandler,
  toggleRegister,
  error,
  loading,
}: IState) => {
  const schema = {
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true,
      length: {
        maximum: 64,
      },
    },
    name: {
      presence: { allowEmpty: false, message: 'is required' },
      length: {
        maximum: 100,
      },
    },
    password: {
      format: {
        pattern: passwordPattern,
        message:
          'should at least be 8 characters long, have uppercase letter(s), lowercase letter(s), number(s), and sepecial character(s)',
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: 'is required' },
      equality: 'password',
    },
  }

  const [formState, setFormState] = useState<FormState>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors: any = validate(formState.values, schema)
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleRegister = async (event: any) => {
    event.preventDefault()
    const { name, email, password } = formState.values
    const picture =
      'https://ui-avatars.com/api/?name=' + (name || '').replace(/ /g, '+')

    if (!formState.isValid) {
      return
    }

    if (!name || !email || !password) {
      return
    }

    completeHandler(name, email, password, picture)
  }

  return (
    <>
      <form className="register-popup__form" onSubmit={handleRegister}>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="text"
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <input
                type="password"
                name="confirmPassword"
                placeholder="confirmPassword"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="input-group">
              <button
                type="submit"
                className={
                  !formState.isValid ? 'thm-btn thm-btn-disabled' : 'thm-btn'
                }
                style={{ width: '100%' }}
              >
                {!loading ? 'Finish Registration' : <CircularProgress />}
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            Already have an account?{' '}
            <Link onClick={() => toggleRegister(false)}>Sign In</Link>
          </div>
        </div>
        <Zoom
          in={!!error && !loading}
          style={{ transitionDelay: error ? '100ms' : '0ms' }}
        >
          <Alert severity="error" variant={'filled'}>
            <AlertTitle>Error</AlertTitle>
            <strong>{error && error.message}</strong>
          </Alert>
        </Zoom>
      </form>
    </>
  )
}

export default Register
